<template>
  <v-dialog
    v-model="activo"
    max-width="55%"
  >
    <v-card>
      <v-card-title class="indigo lighten-1 white--text" height="15%">
          <span class="headline">Devolver Paquete</span>
      </v-card-title>
      <br/>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete 
              v-model="selectedBodega" 
              :items="bodegasMovim" 
              item-text="bodega_nombre" 
              label="Bodega destino" 
              dense
              hide-details
              return-object
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              v-model="observacion"
              outlined
              dense
              label="Ingrese el motivo de Devolución"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click="closeModal">Cancelar</v-btn>
        <v-btn color="success" @click="devolverPaquete">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    bodegas: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      selectedBodega: {},
      observacion: ''
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalMovimientos', value)
      }
    },
    bodegasMovim: {
      get(){
        return this.bodegas
      },
    }
  },
  methods: {
    devolverPaquete(){
      if (Object.keys(this.selectedBodega).length == 0){
        this.$store.dispatch('executeSnackBar', {msj: 'Debe seleccionar una bodega para poder hacer la devolución.', color: 'warning', activo: true})
        return
      }
      this.$emit('devolverPaquete', {devolver: true, bodegaDestino: this.selectedBodega.bodega_codigo, observacion: this.observacion})
      this.selectedBodega = {}
      this.observacion = ''
    },
    closeModal(){
      this.$emit('devolverPaquete', {devolver: false, bodegaDestino: null, observacion: ''})
      this.selectedBodega = {}
      this.observacion = ''
    }
  },
}
</script>

<style>

</style>