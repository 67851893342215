<template>
  <v-dialog v-model="activo" max-width="65%">
    <v-card>
      <v-card-title class="green white--text" height="15%">
        <span class="headline">Ver Paquete</span>
      </v-card-title>
      <br/>
      <v-card-text>
        <v-container>
          <v-form ref="formPaquetes" v-model="formPaquete">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="cliente" label="Apellido y Nombre" readonly dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="documento" label="Documento" readonly dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="telefono" label="Telefono" readonly dense></v-text-field>
              </v-col>
              <v-col cols="12"  sm="6" md="6">
                <v-text-field v-model="direccion" label="Direccion" readonly dense></v-text-field>
              </v-col>
              <v-col cols="12"  sm="12" md="12">
                <v-text-field v-model="localidad" label="Localidad" readonly dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete 
                  v-model="deBodega" 
                  :items="bodegasO" 
                  item-text="bodega_nombre"
                  hide-details
                  disabled 
                  label="De Bodega" 
                  dense
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete 
                  v-model="aBodega" 
                  :items="bodegasD" 
                  item-text="bodega_nombre"
                  hide-details
                  disabled 
                  label="A Bodega" 
                  dense
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea v-model="observacion" clearable counter :rows="2" filled label="Observacion" readonly dense></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-row v-if="detallesPaquete.length > 0">
            <v-col cols="12">
              <v-toolbar color="green" class="white--text">
                <v-toolbar-title>
                    Detalle de Paquete
                </v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12">
              <v-data-table 
                :headers="itemsHeaders" 
                :items="detallesPaquete" 
                dense
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="activo = false">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    ver: {
      type: Boolean,
      default: false
    },
    datos: {
      type: Object,
      default: {}
    },
    bodegasDestino: {
      type: Array,
      default: []
    },
    bodegasOrigen: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      itemsHeaders: [
        { text: 'Item Codigo', align: 'center', value: 'item_codigo'},
        { text: 'Item Nombre', align: 'center', value: 'item_nombre'},
        { text: 'Cantidad', align: 'center', value: 'item_cantidad'}
      ],
      formPaquete: false
    }
  },
  computed: {
    activo: {
      get(){
        return this.ver
      },
      set(value){
        if (value == false){
          this.$emit('closeModalVer', value)
        }
      }
    },
    detallesPaquete: {
      get(){
        return this.datos.itemsPaquete
      }
    },
    cliente: {
      get(){
        return this.datos.cliente
      }
    },
    documento: {
      get(){
        return this.datos.documento
      }
    },
    telefono: {
      get(){
        return this.datos.telefono
      }
    },
    direccion: {
      get(){
        return this.datos.direccion
      }
    },
    localidad: {
      get(){
        return this.datos.localidad
      }
    },
    deBodega: {
      get(){
        return this.datos.deBodega
      }
    },
    aBodega: {
      get(){
        return this.datos.aBodega
      }
    },
    observacion: {
      get(){
        return this.datos.observacion
      }
    },
    bodegasD: {
      get(){
        return this.bodegasDestino
      } 
    },
    bodegasO: {
      get(){
        return this.bodegasOrigen
      } 
    }
  },
}
</script>

<style>

</style>