<template>
  <v-dialog
    v-model="activo"
    max-width="60%"
  >
    <v-card flat>
      <v-card-title class="green white--text" height="15%">
          <span class="headline">Aceptar Paquete</span>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              v-model="observacion"
              outlined
              dense
              label="Ingrese la observación (opcional)."
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click="cerrarModal()">Cancelar</v-btn>
        <v-btn color="success" @click="aceptPaquete()">Aceptar Paquete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalAceptPaq', value)
      }
    },
  },
  data() {
    return {
      observacion: ''
    }
  },
  methods: {
    cerrarModal(){
      this.activo = false
      this.observacion = ''
    },
    aceptPaquete(){
      this.$emit('aceptarPaq', {aceptar: true, observacion: this.observacion})
      this.cerrarModal()
    }
  },
}
</script>

<style>

</style>