<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="55%"
      persistent
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="info">
            <v-toolbar-title class="white--text">
              Rechazar Paquete N° {{ id_paquete }}
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <br/>
        <v-card-text>
          <v-textarea
            v-model="motivo"
            dense
            outlined
            label="Motivo de Rechazo"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cerrarModal">Cancelar</v-btn>
          <v-btn color="success" @click="rechazar">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    paq_id: {
      type: Number,
      default: 0
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalRechazo', value)
      }
    },
    id_paquete: {
      get(){
        return this.paq_id
      }
    }
  },
  data() {
    return {
      motivo: ''
    }
  },
  methods: {
    cerrarModal(){
      this.motivo = ''
      this.activo = false
    },
    rechazar(){
      if (this.motivo.length == 0){
        this.$store.dispatch('executeSnackBar', {msj: 'Debe ingresar un motivo de rechazo para continuar.', color: 'warning', activo: true})
        return
      }
      this.$emit('rechazarPaquete', {rechazar: true, motivo: this.motivo})
      this.cerrarModal()
    }
  },
}
</script>

<style>

</style>