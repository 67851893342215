<template>
  <v-row class="d-flex justify-center mt-16 pt-xl-16">
    <v-col cols="10" sm="6" md="3" xl="2">
      <v-card
        class="d-flex justify-center mt-4"
        style="opacity: 0.8"
      >
        <v-img
          style="position:absolute; z-index: 1; margin-top: -60px;"
          height="120"
          width="120"
          src="../../../public/img/login-icon.png"
        ></v-img>
        <div class="mt-16 pt-4">
          <v-card-title class="d-flex justify-center text-h5">
            Iniciar sesión
          </v-card-title>
          <v-card-text>
            <v-form class="mx-12 mt-4 mb-8" @submit.prevent="login">
              <v-text-field
                v-model.trim="user"
                label="Usuario"
                prepend-icon="fas fa-user"
              ></v-text-field>
              <v-text-field
                v-model.trim="password"
                type="password"
                label="Clave"
                prepend-icon="fas fa-lock"
              ></v-text-field>
              <v-btn
                color="primary"
                class="mt-4"
                type="submit"
                :loading="load"
                block
              >
                Ingresar
              </v-btn>
            </v-form>
            <v-expand-transition>
              <v-alert
                v-if="error"
                type="error"
                dense
              >
                {{ message }}
              </v-alert>
            </v-expand-transition>
            <v-footer
              class="d-flex justify-center primary"
              style="opacity: 0.8"
              dark
              app
            >
              <span>
                &copy; {{new Date().getFullYear()}}
                <v-hover v-slot="{ hover }">
                  <a
                    class="white--text"
                    :class="hover ? '' : 'text-decoration-none'"
                    href="https://www.borigenbetzel.com.ar"
                    target="_blank"
                  >
                    Borigen Betzel
                  </a>
                </v-hover>
              </span>
            </v-footer>
          </v-card-text>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {mapState} from 'vuex'
  import router from '../../router'
  export default {
    data () {
      return {
        load: false,
        error: false,
        dialog: false,
        user: '',
        password: '',
        message: '',
      }
    },
    computed: {
      ...mapState(['respTokenApi'])
    },
    created () {
      this.checkLoginParams()
    },
    methods: {
      async login () {
        this.message = ''
        this.error = false
        this.load = true
        await this.$store.dispatch('executeLoginApi', {user: this.user, password: this.password})
      },
      checkLoginParams(){
        if (this.$route.params.hasOwnProperty('expirado') && this.$route.params.expirado == 1){
          this.message = 'Sesión expirada, por favor vuelva a ingresar.'
          this.error = true
          return
        }
      }
    },
    watch: {
      respTokenApi: function(){
        this.load = false
        if (this.respTokenApi.resultado == 1){
          router.push({name: 'Home'})
        }else{
          this.message = this.respTokenApi.msj
          this.error = true
          return
        }
      }
    },
  }
</script>