<template>
  <div>
    <v-dialog v-model="activo" max-width="55%">
      <v-card>
        <v-card-title class="indigo lighten-1 white--text" height="15%">
            <span class="headline">Paquete - Movimiento</span>
        </v-card-title>
        <br/>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete 
                v-model="selectedBodega" 
                :items="bodegasMovim" 
                item-text="bodega_nombre" 
                label="Bodega destino" 
                dense
                hide-details
                return-object
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <v-autocomplete 
                v-model="selectedRepartidor" 
                :items="repartidoresMovim"
                item-text="repartidor_nombre" 
                label="Repartidor" 
                dense
                hide-details
                return-object
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cerrarModalMovimiento">Cancelar</v-btn>
          <v-btn color="success" @click="validarMovimiento">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    bodegas: {
      type: Array,
      default: []
    },
    repartidores: {
      type: Array,
      default: []
    },
    dialogActivo: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  data() {
    return {
      selectedBodega: {},
      selectedRepartidor: {}
    }
  },
  computed: {
    bodegasMovim: {
      get(){
        return this.bodegas
      }
    },
    repartidoresMovim: {
      get(){
        return this.repartidores
      }
    },
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalMovimientos', value)
      }
    }
  },
  methods: {
    async validarMovimiento(){
      if (Object.keys(this.selectedBodega).length == 0){
        // debe ingresar al menos la bodega
        this.$store.dispatch('executeSnackBar', {msj: 'Debe seleccionar la Bodega de Destino.', color: 'error', activo: true})
        return
      }
      // controlo los movimientos de paquetes
      this.$store.state.msjModalCarga = 'Validando Movimientos del Paquete...'
      this.$store.state.modalCarga = true
      // control de movimientos pendientes
      let pendientesPeticion = await this.$store.dispatch('paquetes/getMovimientosPendientes', {paquete_id: this.id})
      this.$store.state.modalCarga = false
      if (pendientesPeticion.resultado == 0){
        this.$store.dispatch('executeSnackBar', {msj: pendientesPeticion.msj, color: 'error', activo: true})
        return
      }
      // emito
      this.$emit('setPendientes', {movimientos: pendientesPeticion.movimientos, bodega: this.selectedBodega, repartidor: this.selectedRepartidor})
    },
    cerrarModalMovimiento(){
      this.selectedBodega = {}
      this.selectedRepartidor = {}
      if (this.activo == true){
        this.activo = false
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.cerrarModalMovimiento()
      }
    }
  },
}
</script>

<style>

</style>