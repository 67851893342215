<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Encabezado -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="info"
              small
              fab
              @click="generarDevolucion()"
              title="Generar Devolución"
            >
              <v-icon>fas fa-reply</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Tipo de Envio
                    <v-autocomplete
                      v-model="selectedTipoEnvio"
                      item-text="tipo_descripcion"
                      :items="tiposEnvios"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    De Bodega
                    <v-autocomplete
                      v-model="selectedBodegaOrigen"
                      item-text="bodega_nombre"
                      :items="bodegas"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Fecha
                    <FechaRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    <div class="mt-5">
                      <v-btn
                        icon
                        title="Limpiar Filtros"
                        color="warning"
                        class="mx-4"
                        @click="limpiarFiltros"
                      ><v-icon>fas fa-broom</v-icon></v-btn>
                      <v-btn
                        title="Buscar"
                        color="info"
                        class="mx-4"
                        type="submit"
                        :loading="load"
                        :disabled="sinRepartidor == true"
                      ><v-icon>fas fa-search</v-icon> Buscar</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Paquetes -->
        <v-data-table
          :headers="headers"
          :items="paquetes"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="primary" @click="visualizar(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 4" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarAcepPaq(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-check</v-icon>
                </v-btn>
              </template>
              <span>Aceptar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 4" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="rechazarPaq(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-times</v-icon>
                </v-btn>
              </template>
              <span>Rechazar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 5" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarEntrega(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-biking</v-icon>
                </v-btn>
              </template>
              <span>Entregar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 5" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="generarEtiqueta(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>Generar Etiqueta</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 5" :disabled="sinRepartidor == true">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="openModalDevolver(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-reply</v-icon>
                </v-btn>
              </template>
              <span>Generar Devolucion</span>
            </v-tooltip>
          </template>
          <!-- Color para el estado del paquete -->
          <template v-slot:[`item.paquete_estado_nombre`]="{ item }">
            <v-chip :color="getColorEstadoPaq(item.paquete_estado_id)" dark>{{ item.paquete_estado_nombre }}</v-chip>
          </template>
          <!-- Color para el estado del movimiento -->
          <template v-slot:[`item.movimiento_estado_nombre`]="{ item }">
            <v-chip :color="getColorEstadoPaq(item.movimiento_estado_id)" dark>{{ item.movimiento_estado_nombre }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Confirmar Acción -->
    <ModalConfirmarAccion 
      @ejecutarAccion="confirmarAccion"
    />
    <!-- Modal Ver Paquete -->
    <ModalVerPaquete 
      :ver="dialogVerPaquete"
      :datos="datosPaquete"
      :bodegasDestino="bodegasD"
      :bodegasOrigen="bodegas"
      @closeModalVer="cerrarModalVer"
    />
    <!-- Modal Generar Devolucion un Paquete -->
    <ModalGenerarDevolucion 
      :dialogActivo="dialogMovPaquete"
      :bodegas="bodegasD"
      @setearModalMovimientos="setModalDevolucion"
      @devolverPaquete="confirmarMovimiento"
    />
    <!-- Modal Rechazar Paquete -->
    <ModalRechazarPaquete 
      :dialogActivo="dialogRechazo"
      :paq_id="parseInt(selectedPaquete.paquete_id)"
      @setearModalRechazo="setModalRechazo"
      @rechazarPaquete="confirmarRechazo"
    />
    <!-- Modal Generar varias Devoluciones -->
    <ModalGenerarDevoluciones 
      :dialogActivo="dialogDev"
      :bodegas="bodegasD"
      :repartidor="parseInt(repartidor.repartidor_id)"
      @setearModalDev="setModalDev"
      @confirmDevolver="confirmarDevolucionMasiva"
    />
  </div>
</template>

<script>
  import FechaRango from '../../components/util/FechaRango.vue'
  import SearchDataTable from '../../components/util/SearchDataTable.vue'
  import ModalVerPaquete from '../../components/paquetes/ModalVerPaquete.vue'
  import ModalConfirmarAccion from '../../components/generales/ModalConfirmarAccion.vue'
  import ModalGenerarDevolucion from '../../components/repartidores/ModalGenerarDevolucion.vue'
  import ModalRechazarPaquete from '../../components/repartidores/ModalRechazarPaquete.vue'
  import ModalGenerarDevoluciones from '../../components/repartidores/ModalGenerarDevoluciones.vue'
  import moment from 'moment'
  import download from 'downloadjs'
export default {
  data() {
    return {
      panel: 0,
      selectedTipoEnvio: {},
      tiposEnvios: [],
      selectedBodegaOrigen: {},
      bodegas: [],
      bodegasD: [],
      selectedEstado: {},
      estados: [],
      fechaRango: [null, null],
      limpiarFecha: false,
      load: false,
      headers: [
        { text: 'Id Paquete', align: 'center', value: 'paquete_id' },
        { text: 'Comp. Código', align: 'center', value: 'comp_codigo' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Destinatario', align: 'center', value: 'destinatario_nombre' },
        { text: 'Estado Paquete', align: 'center', value: 'paquete_estado_nombre' },
        { text: 'Estado Movimiento', align: 'center', value: 'movimiento_estado_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      paquetes: [],
      search: '',
      provincias: [],
      repartidores: [],
      tienePermiso: 0,
      repartidor: {},
      sinRepartidor: false,
      dialogVerPaquete: false,
      datosPaquete: {
        cliente: '',
        documento: '',
        telefono: '',
        direccion: '',
        localidad: '',
        observacion: '',
        deBodega: {},
        aBodega: {},
        itemsPaquete: []
      },
      selectedPaquete: {},
      bodegaDevolucion: 0,
      opcion: 0,
      dialogMovPaquete: false,
      dialogRechazo: false,
      dialogDev: false,
      paquetesDevolver: [],
      bodegaDevolucionMasiva: 0,
      obsDevolucion: '',
      sortBy: 'paquete_id',
      sortDesc: true
    }
  },
  components: {
    FechaRango,
    SearchDataTable,
    ModalVerPaquete,
    ModalConfirmarAccion,
    ModalGenerarDevolucion,
    ModalRechazarPaquete,
    ModalGenerarDevoluciones
  },
  created(){
    this.initFormRepartidor()
  },
  methods: {
    async initFormRepartidor(){
      this.$store.state.msjModalCarga = 'Iniciando Formulario...'
      this.$store.state.modalCarga = true
      // obtengo todos los datos del Formulario
      let initPeticion = await this.$store.dispatch('paquetes/initFormPaquetes')
      if (initPeticion.resultado == 1){
        this.tiposEnvios = initPeticion.tipos
        this.estados = initPeticion.estados
        this.bodegas = initPeticion.bodegasO
        this.bodegasD = initPeticion.bodegasD
        this.provincias = initPeticion.provincias
        this.repartidores = initPeticion.repartidores
        this.tienePermiso = initPeticion.permiso[0].tienePermiso
        this.getRepartidor()
      }else{
        this.$store.state.modalCarga = false
        this.sinRepartidor = true
        this.tiposEnvios = []
        this.estados = []
        this.bodegas = []
        this.bodegasD = []
        this.provincias = []
        this.repartidores = []
        this.$store.dispatch('executeSnackBar', {msj: initPeticion.message, color: 'error', activo: true})
        return
      }
    },
    async getRepartidor(){
      let repartidorPeticion = await this.$store.dispatch('repartidores/getRepartidor')
      this.$store.state.modalCarga = false
      if (repartidorPeticion.resultado == 1){
        this.repartidor = repartidorPeticion.repartidor[0]
      }else{
        this.repartidor = {}
        this.sinRepartidor = true
        this.$store.dispatch('executeSnackBar', {msj: repartidorPeticion.msj, color: 'error', activo: true})
      }
    },
    getColorEstadoPaq(estado_id){
      if (estado_id == 2) return 'green'
      else if (estado_id == 3 ) return 'red'
      else return 'blue'
    },
    generarDevolucion(){
      this.opcion = 5
      this.dialogDev = true
    },
    confirmarDevolucionMasiva(value){
      this.paquetesDevolver = value.paquetes
      this.bodegaDevolucionMasiva = value.bodegaDestino
      this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Generar Devolución Masiva', texto: 'Se procederá a devolver todos los paquetes del listado. ¿Confirma esta acción?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
    },
    async devolucionMasiva(){
      this.$store.state.msjModalCarga = 'Grabando Devolución...'
      this.$store.state.modalCarga = true
      // armo el body
      let body = {
        paquetes: this.paquetesDevolver,
        repartidor_id: this.repartidor.repartidor_id,
        bodegaDestino: this.bodegaDevolucionMasiva
      }
      let devolverPeticion = await this.$store.dispatch('repartidores/devolucionMasiva', body)
      this.$store.state.modalCarga = false
      if (devolverPeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'Los paquetes fueron devueltos a la bodega indicada correctamente.', color: 'success', activo: true})
      }else{
        this.$store.dispatch('executeSnackBar', {msj: devolverPeticion.msj, color: 'error', activo: true})
      }
    },
    async buscar(){
      this.load = true
      // asigno fechas
      let fechaDesde = moment(this.fechaRango[0]).format('DD/MM/YYYY')
      let fechaHasta = ''
      if (this.fechaRango[1].length > 0){
        fechaHasta = moment(this.fechaRango[0]).format('DD/MM/YYYY')
      }
      // tipo de envio
      let tipoEnvio = 0
      if (Object.keys(this.selectedTipoEnvio).length > 0){
        tipoEnvio = this.selectedTipoEnvio.tipo_id
      }
      // bodega origen
      let deBodega = 0
      if (Object.keys(this.selectedBodegaOrigen).length > 0){
        deBodega = this.selectedBodegaOrigen.bodega_codigo
      }
      // estado de paquete
      let estadoPquete = []
      if (Object.keys(this.selectedEstado).length > 0){
        estadoPquete.push(this.selectedEstado.estado_id)
      }
      // estado de movimiento
      let estadoMov = []
      this.paquetes = []
      // body para la api
      let bodyBusqueda = {
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        tipoEnvio: tipoEnvio,
        deBodega: deBodega,
        estadoPquete, estadoPquete,
        usuario: this.$store.state.username,
        estadoMov: estadoMov,
        repartidor_id: this.repartidor.repartidor_id
      }
      let paquetesPeticion = await this.$store.dispatch('paquetes/getPaquetes', bodyBusqueda)
      this.load = false
      if (paquetesPeticion.resultado == 1){
        this.paquetes = paquetesPeticion.paquetes
      }else{
        this.paquetes = []
        this.$store.dispatch('executeSnackBar', {msj: paquetesPeticion.msj, color: 'error', activo: true})
      }
    },
    limpiarFiltros(){
      this.selectedTipoEnvio = {}
      this.selectedBodegaOrigen = {}
      this.selectedEstado = {}
      this.limpiarFecha = true
    },
    async visualizar(item){
      this.$store.state.msjModalCarga = 'Buscando Detalles...'
      this.$store.state.modalCarga = true
      this.datosPaquete.cliente = item.destinatario_nombre
      this.datosPaquete.documento = item.destinatario_nro_doc
      this.datosPaquete.telefono = item.destinatario_telefono
      this.datosPaquete.direccion = item.destinatario_domicilio
      this.datosPaquete.localidad = item.destinatario_localidad
      this.datosPaquete.observacion = item.observacion
      // obtengo bodega origen
      let bodegaOrig = this.bodegas.filter(element => element.bodega_codigo == item.bodega_origen)
      if (bodegaOrig.length > 0){
        this.datosPaquete.deBodega = bodegaOrig[0]
      }
      // obtengo la bodega destino
      let bodegaDes = this.bodegasD.filter(element => element.bodega_codigo == item.bodega_destino)
      if (bodegaDes.length > 0){
        this.datosPaquete.aBodega = bodegaDes[0]
      }
      this.datosPaquete.itemsPaquete = []
      let detallesPeticion = await this.$store.dispatch('paquetes/getDetallesPaquete', {paquete_id: item.paquete_id})
      this.$store.state.modalCarga = false
      if (detallesPeticion.resultado == 1){
        this.datosPaquete.itemsPaquete = detallesPeticion.paquetes
      }else{
        // mostrar el error y abrir el modal
        this.$store.dispatch('executeSnackBar', {msj: detallesPeticion.msj, color: 'error', activo: true})
      }
      this.dialogVerPaquete = true
    },
    confirmarAccion(value){
      if (value){
        if (this.opcion == 1){
          this.aceptarPaquete()
        }
        if (this.opcion == 2){
          this.generarMovimiento()
        }
        if (this.opcion == 3){
          this.entregarPaquete()
        }
        if (this.opcion == 4){
          this.rechazarPaquete()
        }
        if (this.opcion == 5){
          this.devolucionMasiva()
        }
      }else{
        this.motivoRechazo = ''
        this.paquetesDevolver = []
        this.bodegaDevolucionMasiva = 0
      }
      this.$store.dispatch('executeModalAccion', {activo: false, titulo: '', texto: '', colorTitulo: '', colorAceptar: '', colorCancelar: ''})
    },
    confirmarAcepPaq(item){
      this.opcion = 1
      this.selectedPaquete = item
      this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Aceptar Paquete', texto: 'Está aceptando el paquete N°' + item.paquete_id + '. ¿Confirma esta acción?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
    },
    rechazarPaq(item){
      this.opcion = 4
      this.selectedPaquete = item
      this.dialogRechazo = true
    },
    confirmarRechazo(value){
      if (value.rechazar == true){
        this.motivoRechazo = value.motivo
        this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Rechazar Paquete', texto: 'Está rechazando el paquete N°' + this.selectedPaquete.paquete_id + '. ¿Confirma esta acción?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
      }
    },
    async rechazarPaquete(){
      this.$store.state.msjModalCarga = 'Rechazando Paquete...'
      this.$store.state.modalCarga = true
      // armo el body
      let body = {
        paquete_id: this.selectedPaquete.paquete_id,
        motivo: this.motivoRechazo,
        repartidor_id: this.repartidor.repartidor_id
      }
      let rechazoPeticion = await this.$store.dispatch('repartidores/rechazarPaquete', body)
      this.$store.state.modalCarga = false
      if (rechazoPeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'El paquete fue rechazado exitosamente.', color: 'success', activo: true})
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('executeSnackBar', {msj: rechazoPeticion.msj, color: 'error', activo: true})
      }
    },
    async confirmarEntrega(item){
      this.opcion = 3
      this.selectedPaquete = item
      if (item.paquete_tipo_id == 4){
        this.$store.state.msjModalCarga = 'Validando Saldos...'
        this.$store.state.modalCarga = true
        let saldosPeticion = await this.$store.dispatch('repartidores/validarCtaCorriente', {paquete_id: item.paquete_id})
        this.$store.state.modalCarga = false
        if (saldosPeticion.resultado == 1){
          if ((parseInt(saldosPeticion.saldos[0].limite_credito) - parseInt(saldosPeticion.saldos[0].saldo) + 
          parseInt(saldosPeticion.saldos[0].total_posterior)) < 0){
            this.$store.dispatch('executeSnackBar', {msj: 'El Saldo Actual del Cliente ($' + saldosPeticion.saldos[0].saldo + ') supera su límite de crédito ($' + saldosPeticion.saldos[0].limite_credito + '). No se puede registrar la entrega del paquete.', color: 'info', activo: true})
            return
          }else{
            this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Entregar Paquete', texto: 'Está entregando el paquete N°' + item.paquete_id + '. ¿Confirma esta acción?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
          }
        }else{
          this.$store.dispatch('executeSnackBar', {msj: saldosPeticion.msj, color: 'error', activo: true})
        }
      }else{
        this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Entregar Paquete', texto: 'Está entregando el paquete N°' + item.paquete_id + '. ¿Confirma esta acción?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
      }
    },
    async entregarPaquete(){
      this.$store.state.msjModalCarga = 'Entregando Paquete...'
      this.$store.state.modalCarga = true
      let entregaPeticion = await this.$store.dispatch('repartidores/entregarPaquete', {paquete_id: this.selectedPaquete.paquete_id})
      this.$store.state.modalCarga = false
      if (entregaPeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'El paquete fue entregado exitosamente.', color: 'success', activo: true})
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('executeSnackBar', {msj: entregaPeticion.msj, color: 'error', activo: true})
      }
    },
    async generarEtiqueta(item){
      this.$store.state.msjModalCarga = 'Generando etiqueta...'
      this.$store.state.modalCarga = true
      // armo mi body para la API
      let body = {
        destinatario: item.destinatario_nombre,
        documento: item.destinatario_nro_doc,
        domicilio: item.destinatario_domicilio,
        localidad: item.destinatario_localidad,
        telefono: item.destinatario_telefono,
        paquete_id: item.paquete_id
      }
      let etiquetaPedido = await this.$store.dispatch('paquetes/generateLabel', body)
      this.$store.state.modalCarga = false
      if (etiquetaPedido.resultado == 1){
        download(etiquetaPedido.archivo, 'Etiqueta.pdf', 'application/pdf')
      }else{
        this.$store.dispatch('executeSnackBar', {msj: etiquetaPedido.msj, color: 'error', activo: true})
      }
    },
    openModalDevolver(item){
      this.opcion = 2
      this.selectedPaquete = item
      this.dialogMovPaquete = true
    },
    confirmarMovimiento(value){
      if (value.devolver == true){
        this.bodegaDevolucion = value.bodegaDestino
        this.obsDevolucion = value.observacion
        this.$store.dispatch('executeModalAccion', {
          activo: true, 
          titulo: 'Devolver Paquete', 
          texto: 'Está devolviendo el paquete N°' + this.selectedPaquete.paquete_id + '. ¿Confirma esta acción?', 
          colorTitulo: 'info', 
          colorAceptar: 'success', 
          colorCancelar: 'warning'
        })
      }else{
        this.dialogMovPaquete = false
        this.bodegaDevolucion = 0
        this.obsDevolucion = ''
      }
    },
    cerrarModalVer(value){
      this.dialogVerPaquete = value
    },
    async aceptarPaquete(){
      this.$store.state.msjModalCarga = 'Aceptando Paquete...'
      this.$store.state.modalCarga = true
      // armo el body para la peticion
      let body = {
        repartidor_id: this.repartidor.repartidor_id,
        paquete_id: this.selectedPaquete.paquete_id
      }
      let aceptarPeticion = await this.$store.dispatch('repartidores/aceptarPaquete', body)
      this.$store.state.modalCarga = false
      if (aceptarPeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'El paquete fue aceptado exitosamente.', color: 'success', activo: true})
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('executeSnackBar', {msj: aceptarPeticion.msj, color: 'error', activo: true})
      }
    },
    setModalDevolucion(value){
      this.dialogMovPaquete = value
    },
    async generarMovimiento(){
      // cierro el modal de devolucion
      this.dialogMovPaquete = false
      this.$store.state.msjModalCarga = 'Devolviendo Paquete...'
      this.$store.state.modalCarga = true
      // armo el body a enviar
      let body = {
        bodega_origen: this.selectedPaquete.bodega_actual_codigo,
        bodega_destino: this.bodegaDevolucion,
        observacion: this.obsDevolucion,
        paquete_id: this.selectedPaquete.paquete_id,
        repartidor_id: this.repartidor.repartidor_id
      }
      let devolucionPeticion = await this.$store.dispatch('repartidores/generarDevolucion', body)
      this.$store.state.modalCarga = false
      if (devolucionPeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'El paquete fue devuelto exitosamente.', color: 'success', activo: true})
        setTimeout(() => [this.buscar()], 2000)
      }else{
        this.$store.dispatch('executeSnackBar', {msj: devolucionPeticion.msj, color: 'error', activo: true})
      }
    },
    setModalRechazo(value){
      this.dialogRechazo = value
    },
    setModalDev(value){
      this.dialogDev = value
    }
  },
  watch: {
    paquetes: function(){
      if (this.paquetes.length > 0){
        for (let id in this.paquetes){
          this.paquetes[id].fecha_log = moment(this.paquetes[id].fecha_log).format('DD/MM/YYYY')
        }
      }
    },
    dialogVerPaquete: function(){
      if (this.dialogVerPaquete == false){
        this.datosPaquete.cliente = ''
        this.datosPaquete.documento = ''
        this.datosPaquete.telefono = ''
        this.datosPaquete.direccion = ''
        this.datosPaquete.localidad = ''
        this.datosPaquete.observacion = ''
        this.datosPaquete.deBodega = ''
        this.datosPaquete.aBodega = ''
        this.datosPaquete.itemsPaquete = []
      }
    }
  },
}
</script>

<style>

</style>