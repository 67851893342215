<template>
  <div>
    <v-dialog v-model="activo" persistent max-width="60%">
      <v-card  class="mx-auto" height="100%">
        <v-card-title class="blue white--text" height="30%">
          <v-icon color="white" class="mr-2" >fas fa-truck</v-icon>
          <span class="headline">Generar Lote</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <br/>
        <v-card-text>
          <v-form ref="formLote" v-model="formLote" @submit.prevent="grabarLote">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                De Bodega
                <v-autocomplete 
                  v-model="selectedBodegaOrigen" 
                  :items="bodegasD" 
                  :rules="loteRules" 
                  item-text="bodega_nombre"
                  @change="controlBodega()" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="7" md="7">
                Fecha
                <FechaRango
                  v-model="fechaRango"
                  :limpiar.sync="limpiarFecha"
                />
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <br/>
                <v-btn color="primary" @click="getPaquetes()" fab dark title="Cargar Paquetes" small><v-icon small>fas fa-download</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                A Bodega
                <v-autocomplete 
                  v-model="selectedBodegaDestino" 
                  :items="bodegasA" 
                  :rules="loteRules" 
                  item-text="bodega_nombre"
                  @change="controlBodega()" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                Repartidor
                <v-autocomplete 
                  v-model="selectedRepartidor" 
                  :items="listRepartidores" 
                  :rules="loteRules" 
                  item-text="repartidor_nombre"
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Código</th>
                      <th class="text-left">Cliente</th>
                      <th class="text-left">Documento N°</th>
                      <th class="text-left">Direccion</th>
                      <th class="text-left">Localidad</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in paquetes" :key="item.name">
                      <td>{{ item.paquete_id }}</td>
                      <td>{{ item.destinatario_nombre }}</td>
                      <td>{{ item.destinatario_nro_doc }}</td>
                      <td>{{ item.destinatario_domicilio }}</td>
                      <td>{{ item.destinatario_localidad }}</td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn small icon color="error" @click="eliminardet(item)" v-on="on">
                              <v-icon  class="mr-2" >fas fa-times-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="2">
                <v-btn block color="warning" @click="cerrarModal">Cancelar</v-btn>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn type="submit" block color="success">Grabar</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import FechaRango from '../util/FechaRango.vue'
  import moment from 'moment'
export default {
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    bodegasOrig: {
      type: Array,
      default: []
    },
    bodegasDest: {
      type: Array,
      default: []
    },
    repartidores: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      loteRules: [
        v => !!v || 'Campo obligatorio.',
      ],
      formLote: false,
      selectedBodegaOrigen: {},
      selectedBodegaDestino: {},
      selectedRepartidor: {},
      paquetes: [],
      fechaRango: [ null, null ],
      limpiarFecha: false
    }
  },
  components: {
    FechaRango
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalAddLote', value)
      }
    },
    listRepartidores: {
      get(){
        return this.repartidores
      }
    },
    bodegasA: {
      get(){
        return this.bodegasDest
      }
    },
    bodegasD: {
      get(){
        return this.bodegasOrig
      }
    }
  },
  methods: {
    async getPaquetes(){
      if (Object.keys(this.selectedBodegaOrigen).length == 0){
        this.$store.dispatch('executeSnackBar', {msj: 'Debe seleccionar la Bodega de Origen para proceder.', color: 'warning', activo: true})
        return
      }
      this.paquetes = []
      this.$store.state.msjModalCarga = 'Obteniendo paquetes...'
      this.$store.state.modalCarga = true
      // fechas
      let fechaDesde = moment(this.fechaRango[0]).format('DD/MM/YYYY')
      let fechaHasta = ''
      if (this.fechaRango[1].length > 0){
        fechaHasta = moment(this.fechaRango[0]).format('DD/MM/YYYY')
      }
      // estados
      let estadoPquete = [1]
      let estadoMov = [4, 6]
      // genero el body para la consulta
      let body = {
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        tipoEnvio: 0,
        deBodega: this.selectedBodegaOrigen.bodega_codigo,
        estadoPquete: estadoPquete,
        usuario: this.$store.state.username,
        estadoMov: estadoMov,
        repartidor_id: 0
      }
      let paquetesPeticion = await this.$store.dispatch('paquetes/getPaquetes', body)
      this.$store.state.modalCarga = false
      if (paquetesPeticion.resultado == 1){
        this.paquetes = paquetesPeticion.paquetes
      }else{
        this.$store.dispatch('executeSnackBar', {msj: 'Debe seleccionar la Bodega de Origen para proceder.', color: 'warning', activo: true})
      }
    },
    eliminardet(detalle){
      let pos = this.paquetes.indexOf(detalle)
      this.paquetes.splice(pos, 1)
    },
    limpiarCampos(){
      this.selectedBodegaDestino = {}
      this.selectedBodegaOrigen = {}
      this.selectedRepartidor = {}
      this.paquetes = []
      this.limpiarFecha = true
    },
    cerrarModal(){
      this.limpiarCampos()
      if (this.activo == true){
        this.activo = false
      }
    },
    controlBodega(){
      if (Object.keys(this.selectedBodegaDestino).length > 0 && Object.keys(this.selectedBodegaOrigen).length > 0){
        if (this.selectedBodegaDestino.bodega_codigo == this.selectedBodegaOrigen.bodega_codigo){
          this.$store.dispatch('executeSnackBar', {msj: 'Las bodegas de origen y destino no pueden ser las mismas.', color: 'warning', activo: true})
          this.limpiarCampos()
          return
        }
      }
    },
    camposValidos(){
      if (Object.keys(this.selectedBodegaOrigen).length == 0){
        this.$store.dispatch('executeSnackBar', {msj: 'Debe ingresar la Bodega de Origen.', color: 'warning', activo: true})
        return false
      }
      if (Object.keys(this.selectedBodegaDestino).length == 0){
        this.$store.dispatch('executeSnackBar', {msj: 'Debe ingresar la Bodega de Destino.', color: 'warning', activo: true})
        return false
      }
      if (Object.keys(this.selectedRepartidor).length == 0){
        this.$store.dispatch('executeSnackBar', {msj: 'Debe ingresar el repartidor.', color: 'warning', activo: true})
        return false
      }
      if(this.paquetes.length == 0){
        this.$store.dispatch('executeSnackBar', {msj: 'Es necesario agregar paquetes para grabar un Lote.', color: 'warning', activo: true})
        return false
      }
      return true
    },
    async grabarLote(value){
      if(!this.$refs.formLote.validate()){
        return
      }
      if (!this.camposValidos()){
        return
      }
      // armo el body que voy a mandar a la API
      let body = {
        bodega_origen: this.selectedBodegaOrigen.bodega_codigo,
        bodega_destino: this.selectedBodegaDestino.bodega_codigo,
        repartidor_id: this.selectedRepartidor.repartidor_codigo,
        paquetes: this.paquetes
      }
      this.$emit('confirmLote', body)
    }
  },
  watch: {
    paquetes: function(){
      if (this.paquetes.length > 0){
        for (let id in this.paquetes){
          this.paquetes[id].fecha_log = moment(this.paquetes[id].fecha_log).format('DD/MM/YYYY')
        }
      }
    },
    activo: function(){
      if (this.activo == false){
        this.cerrarModal()
      }
    }
  },
}
</script>

<style>

</style>